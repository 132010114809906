<template>
  <div class="update-membership-reward-setting-container wrapper">
    <CRow class>
      <CCol lg="6" md="6">
        <CCard>
          <CCardHeader>
            <h1>{{$t('COMMISSION_PAYOUT_MIN_PAGE_TITLE')}}</h1>
            <p>{{$t('COMMISSION_PAYOUT_MIN_PAGE_DESCRIPTION')}}</p>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider
                  name="COMMISSION_PAYOUT_MIN_PAGE_LABEL_AMOUNT"
                  rules="required|min_value:0"
                >
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6" class="label-group">
                      <label
                        class="col-form-label"
                      >{{$t('COMMISSION_PAYOUT_MIN_PAGE_LABEL_AMOUNT')}}</label>
                    </CCol>
                    <CCol lg="6" md="6">
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="config.minimumClaimAmount" />
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                          >{{$t('COMMISSION_PAYOUT_MIN_PAGE_LABEL_CURRENCY_UNIT')}}</div>
                        </div>
                      </div>

                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>
                <ValidationProvider
                  name="MEMBERSHIP_REWARD_SETTING_PAGE_NETWORK_FEE_LABEL_AMOUNT"
                  rules="required|min_value:0"
                >
                  <CRow class="form-group" slot-scope="{ errors }">
                    <CCol lg="6" md="6" class="label-group">
                      <label class="col-form-label">{{$t('MEMBERSHIP_REWARD_SETTING_PAGE_NETWORK_FEE_LABEL_AMOUNT')}}</label>
                    </CCol>
                    <CCol lg="6" md="6">
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="config.networkFee" />
                        <div class="input-group-prepend">
                          <div
                            class="input-group-text"
                          >{{$t('MEMBERSHIP_REWARD_SETTING_PAGE_NETWORK_FEE_LABEL_CURRENCY_UNIT')}}</div>
                        </div>
                      </div>

                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>
                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      v-if="isAllowedUpdating"
                      color="primary"
                      class="btn btn-primary"
                      @click="onSubmit"
                    >{{$t('COMMISSION_PAYOUT_MIN_PAGE_BUTTON_SAVE')}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus } from '@/enums';
import { endpoints, env } from '@/constants';

export default {
  name: 'ClaimMembershipRewardSetting',
  components: {
    VclList,
  },
  data() {
    return {
      config: {
        minimumClaimAmount: null,
        networkFee: null,
      },
      isLoading: true,
      isProcessing: false,
      isAllowedUpdating: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    this.getMembershipRewardSetting();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_UPDATE_CLAIM_MEMBERSHIP_REWARD_SETTING') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getMembershipRewardSetting() {
      try {
        const result = await this.$http.get(endpoints.getMembershipRewardSetting, {});
        this.config.minimumClaimAmount = Number(result.data.membership_commission_usdt_minimum_claim_amount);
        this.config.networkFee = Number(result.data.membership_commission_usdt_network_fee);
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },

    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.update();
      });
    },
    async update() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          membership_commission_usdt_minimum_claim_amount: this.config.minimumClaimAmount,
          membership_commission_usdt_network_fee: this.config.networkFee,
        };
        const result = await this.$http.put(endpoints.updateMembershipRewardSetting, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Setting has been updated',
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-membership-reward-setting-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #3e4b65;
        padding-top: 15px;
        margin-bottom: 15px;
      }
    }
    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .label-group {
      text-align: right;
      label {
        font-size: 14px;
        font-weight: 300;
        color: #3e4b65;
      }
    }
    .input-group {
      input {
        flex: 0 0 50%;
        width: 50%;
        font-size: 16px;
        border-radius: 4px !important;
        color: #3a4a7f;
      }
      .input-group-prepend {
        .input-group-text {
          width: 65px;
          background-color: unset;
          border: none;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 36px;
        padding: 0 30px;
        color: #fff;
        margin: 30px 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }
}
</style>
